<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer"
        >Configuraciones</a
      >
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Asignar Responsable</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <Toast />
    <div
      class="flex align-items-start flex-column lg:justify-content-between lg:flex-row"
    >
      <div class="font-medium text-3xl text-900">
        Asignar Responsabilidad a: {{ user.name }}
      </div>
    </div>
    <!-- <div class="row">
      <div class="col-md-6">
        <label for="">{{ user.name }}</label>
      </div>
      <div class="col-md-6">
        <Button
          label="Guardar"
          icon="pi pi-save"
          class="p-button-success"
          @click="guardarCambios()"
        />
      </div>
    </div> -->

    <DataTable
      ref="UsuariosTablaResponsableSucursal"
      :value="sucursales"
      editMode="cell"
      class="table table-striped table-bordered p-datatable-sm mt-4"
      :rowHover="true"
      responsiveLayout="scroll"
      showGridlines
    >
      <Column field="nombre" header="SUCURSAL" />
      <Column
        v-for="permiso in permisos"
        :key="permiso.id"
        :header="permiso.name"
        :field="permiso.id + '_' + permiso.name"
      >
        <template #body="{ data, field }">
          <Checkbox
            v-model="sucursal_permisos[data.id][field]"
            :binary="true"
            disabled
            v-tooltip.top="'Permitir: ' + field.split('_')[1]"
          ></Checkbox>
        </template>
        <template #editor="{ data, field }">
          <Checkbox
            v-model="sucursal_permisos[data.id][field]"
            :binary="true"
            v-tooltip.top="'Permitir: ' + field.split('_')[1]"
          ></Checkbox>
        </template>
      </Column>
      <template #footer>
        <div class="row">
          <div class="col-md-12 text-right">
            <Button
              label="Cancelar"
              icon="pi pi-times"
              class="p-button-danger p-button-lg mr-2"
              @click="goBack()"
            />
            <Button
              label="Asignar"
              icon="pi pi-save"
              class="p-button-primary p-button-lg"
              @click="guardarCambios()"
            />
          </div>
        </div>
      </template>
    </DataTable>
  </div>
</template>
<script>
import UserService from "@/service/UserService.js";

export default {
  data() {
    return {
      id: this.$route.params.id,
      sucursales: [],
      user: {},
      permisos: [],
      sucursal_permisos: [],
      data_tmp: 100,
    };
  },
  userService: null,
  created() {
    this.userService = new UserService();
    this.obtenerResponsableUsuario();
  },
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    guardarCambios() {
      let datos = {
        user_id: this.id,
        sucursal_permisos: this.sucursal_permisos,
      };
      this.userService
        .actualizarResponsableSucursalesPermisos(datos)
        .then((response) => {
          this.$toast.add({
            severity: "success",
            summary: "Exito",
            detail: response.message,
            life: 3000,
          });
        });
    },
    /* onCellEditComplete(e) {
      console.log(e);
    }, */
    obtenerResponsableUsuario() {
      this.userService.obtenerDatosResponsableUser(this.id).then((response) => {
        this.user = response.user;
        this.permisos = response.permisos;
        this.sucursales = response.sucursales;
        this.sucursal_permisos = response.sucursal_permisos;

        Object.keys(this.user.responsable_sucursales).forEach((sucursal) => {
          Object.keys(this.user.responsable_sucursales[sucursal]).forEach(
            (permiso_id) => {
              this.sucursal_permisos[sucursal][
                permiso_id +
                  "_" +
                  this.user.responsable_sucursales[sucursal][permiso_id]
              ] = true;
            }
          );
        });
      });
    },
  },
};
</script>